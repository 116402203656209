<template>
  <div>
    <b-table :fields="fields"
             :items="items"
             head-variant="light"
             hover
             small
             stacked="md"
             style="word-break: break-all">
      <template v-for="(value, key) in input_options"
                v-slot:[construct_slot_name(key)]="row">
        <b-form-input v-if="is_input_field_editable(row)"
                      :key="key"
                      v-model=row.item[key]
                      :type="value"
                      class="form-control"/>
        <span v-else
              :key="key"
              v-text="row.item[key]"/>
      </template>

      <template v-if="editable"
                v-slot:cell(action)="row">
        <base-icon-button-delete v-if="!is_last_field(row) && !is_first_field(row)"
                                 title="Remove team member"
                                 @click="remove_member(row.item)"/>

        <base-icon-button-add v-else-if="is_last_field(row) && is_last_field_valid"
                              @click="add_empty_member()"/>
      </template>

    </b-table>
    <h6 v-if="show_validation_text"
        class="magenta_text"
        v-text="validation_text"/>
  </div>
</template>

<script>
  import { email_regex } from '@/assets/validators/regex_validator';
  import BaseIconButtonAdd from '@/components/baseComponents/baseIcons/BaseIconButtonAdd';
  import BaseIconButtonDelete from '@/components/baseComponents/baseIcons/BaseIconButtonDelete';

  export default {
    name: 'team_form_no_account',
    components: { BaseIconButtonDelete, BaseIconButtonAdd },
    props: {
      value: {
        type: Array,
        required: true,
      },
      editable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        fields: [
          'name',
          'email',
          {
            key: 'action',
            thStyle: {
              width: '15%',
              'word-break': 'normal',
            },
          },
        ],
        items: JSON.parse(JSON.stringify(this.value)),
        validation_text: '',
        input_options: { name: 'text', email: 'email' },
      };
    },
    computed: {
      items_without_first_and_last() {
        return this.items.slice(1, -1);
      },
      last_field() {
        return this.items[this.items.length - 1] || {};
      },
      show_validation_text() {
        return this.editable && !(this.is_form_valid || this.is_last_field_valid);
      },
      is_form_valid() {
        const { email, name } = this.last_field;
        return !!(email === '' && name === '' && this.items_without_first_and_last.length);
      },
      is_last_field_valid() {
        const { name, email } = this.last_field;
        if (name === '' || email === '') {
          this.set_validation_text('None of the fields can be empty');
          return false;
        }
        if (this.items.slice(0, -1).some((el) => el.email === email)) {
          this.set_validation_text('Cannot add two identical e-mails');
          return false;
        }
        if (!this.is_text_valid(email, email_regex.regex)) {
          this.set_validation_text('Not valid email address format');
          return false;
        }
        return true;
      },
    },
    methods: {
      construct_slot_name(key) {
        return `cell(${key})`;
      },
      is_last_field(data) {
        return data.index === this.items.length - 1;
      },
      is_first_field(data) {
        return data.index === 0;
      },
      is_input_field_editable(data) {
        return this.is_last_field(data) && this.editable;
      },
      add_empty_member() {
        if (this.is_last_field_valid) {
          this.items.push({ name: '', email: '' });
        }
      },
      remove_member(item) {
        this.items = this.items.filter((el) => el !== item);
      },
      remove_actions_not_editable() {
        if (!this.editable) {
          this.fields = this.fields.filter((el) => el !== 'action');
        }
      },
      prepare_items() {
        const is_empty_items = !this.items.length;
        if (is_empty_items) {
          const { first_name, last_name, email } = this.$store.state.login.user;
          this.items.push({
            name: `${first_name} ${last_name}`,
            email,
          });
          this.add_empty_member();
        }
      },
      set_validation_text(text) {
        this.validation_text = text;
      },
    },
    watch: {
      items: {
        handler() {
          this.$emit('validation', this.is_form_valid);
          this.$emit('input', this.items_without_first_and_last);
        },
        deep: true,
      },
    },
    mounted() {
      this.prepare_items();
      this.remove_actions_not_editable();
    },
  };
</script>

<style lang="scss"
       scoped>
  .magenta_text {
    color: $color-pink-magenta;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }
</style>
