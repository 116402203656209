<template>
  <div>
    <b-row>
      <b-col>
        <h5>Fill in the fields below to make your proposal complete</h5>
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label class="font-weight-bold">Proposal affiliation</label>
        <b-form-select v-model="proposal_data.affiliation"
                       class="mb-2">
          <option v-for="(affiliation, index) in user.affiliations"
                  :key="index"
                  :value="affiliation.organization_id">
            <p v-if="affiliation.organization">{{ affiliation.organization.name }}</p>
            <p v-if="affiliation.department">{{ ' - ' + affiliation.department }}</p>
            <p v-if="affiliation.email">{{ ' <' + affiliation.email + '>' }}</p>
          </option>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label class="font-weight-bold">Do you have any team connected to this proposal?</label>
        <extended-radio-group v-model="proposal_data.is_team"
                              :options="yesNo"
                              class="mb-3"/>
        <div v-if="proposal_data.is_team">
          <label class="font-weight-bold mb-0">Team members</label>
          <p class="grey--text">
            Invitation email will be send to team members. <br>
            The team members will appear in PDF after they confirm invitation
          </p>
          <team_form_no_account v-model="proposal_data.team_members"
                                :editable="true"
                                @validation="team_validation = $event"/>
        </div>
      </b-col>
    </b-row>
    <b-row class="text-right">
      <b-col>
        <b-btn v-if="!second_confirmation"
               :disabled="!proposal_data_validation"
               class="mt-3 mb-4"
               variant="info"
               @click="set_second_confirmation(true)">Confirm changes and exit
        </b-btn>
        <template v-if="second_confirmation">
          <b-row>
            <b-col>
              <label>Are you sure?</label>
              <p class="magenta_text">
                Please, remember that confirmation is final. You won't have the possibility to add another team member!
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <button-spinner :loading="loading"
                              class="ma-1 right"
                              variant="info"
                              @click="add_data_to_ceric_proposal">
                <template v-slot:defaultText>
                  <span>Confirm</span>
                </template>
                <template v-slot:spinnerText>
                  <span> Saving...</span>
                </template>
              </button-spinner>
              <b-btn v-if="!loading"
                     :disabled="!proposal_data_validation"
                     class="ma-1"
                     @click="set_second_confirmation(false)">Cancel
              </b-btn>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import team_form_no_account from '@/components/team_form_no_account';
  import extendedRadioGroup from '@/components/extended-radio-group';
  import buttonSpinner from '@/components/buttonSpinner';
  import yesNo from '@/json/radioButtonYesNo';
  import { mapState } from 'vuex';

  export default {
    name: 'ceric_user_proposal_completion_form',
    components: {
      team_form_no_account,
      extendedRadioGroup,
      buttonSpinner,
    },
    props: {
      object_id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        yesNo,
        team_validation: false,
        proposal_data: {
          affiliation: {},
          is_team: null,
          team_members: [],
        },
        team_options: {
          first: { prepend: 'Full name' },
          second: { prepend: 'Email' },
        },
        second_confirmation: false,
        loading: false,
      };
    },
    computed: {
      proposal_data_validation() {
        if (Object.keys(this.proposal_data.affiliation).length) {
          if ((this.proposal_data.is_team && this.team_validation)
            || this.proposal_data.is_team === false) {
            return true;
          }
        }
        return false;
      },
      ...mapState('login', ['user']),
    },
    methods: {
      set_second_confirmation(value) {
        this.second_confirmation = value;
      },
      add_data_to_ceric_proposal() {
        this.loading = true;
        this.axios.put(`/documents/ceric/${this.object_id}/complete`, this.proposal_data)
          .then((response) => {
            const { msg, error } = response.data;
            if (msg) {
              this.$notify({ type: 'success', title: msg });
              this.$emit('generate_pdf');
            } else {
              this.$notify({ type: 'error', title: error });
              this.loading = false;
            }
          })
          .catch((error) => {
            if (error.response.data.error) {
              this.$notify({ type: 'error', title: error.response.data.error });
              this.$emit('generate_pdf');
            } else {
              this.$notify({ type: 'error', title: 'Connection error' });
              this.loading = false;
            }
          });
      },
    },
    watch: {
      proposal_data: {
        handler() {
          if (!this.proposal_data.is_team && this.proposal_data.team_members.length) {
            this.proposal_data.team_members = [];
          }
          if (this.second_confirmation) {
            this.set_second_confirmation(false);
          }
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  .magenta_text {
    color: $color-pink-magenta;
  }
</style>
