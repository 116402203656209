<template>
  <div>
    <b-card :header="$route.name"
            bg-variant="light"
            header-bg-variant="dark"
            header-text-variant="white">
      <b-tabs no-body>
        <b-container class="pa-0"
                     fluid>
          <slot name="message"/>

          <b-row align-h="between">
            <b-col cols="auto">
              <b-btn class="mt-2"
                     size="lg"
                     variant="info"
                     @click="createNewProposal">CREATE NEW PROPOSAL
              </b-btn>
            </b-col>
            <b-col cols="auto">
              <b-btn v-b-modal.modal_ceric_user_completion
                     class="mt-2"
                     size="lg"
                     variant="info">CERIC PROPOSAL
              </b-btn>
              <modal_ceric_user_completion @set_refresh="set_refresh"/>
            </b-col>
          </b-row>
        </b-container>

        <b-tab active
               class="ma-1 mt-4"
               title="in draft">
          <proposals_list :fields="fields"
                          :infrastructure="url.infrastructure"
                          :main-url-part="`/${url.mainUrlPart}/draft/me`"
                          :proposal-form-url-part="url.proposalFormUrlPart"/>
        </b-tab>

        <b-tab class="ma-1 mt-4"
               title="submitted">
          <proposals_list :fields="submittedFields"
                          :infrastructure="url.infrastructure"
                          :main-url-part="`/${url.mainUrlPart}/submitted/me`"
                          :proposal-form-url-part="url.proposalFormUrlPart"
                          :refresh-submitted="refresh"
                          @set_refresh="set_refresh"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
  import proposals_list from '@/components/proposal/proposals/proposals_list';
  import modal_ceric_user_completion from '@/components/proposal/ceric/modal_ceric_user_completion';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    components: {
      proposals_list,
      modal_ceric_user_completion,
    },
    props: {
      url: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        fields: [
          ordinalNumber,
          {
            key: 'title',
            label: 'Title',
            sortable: true,
          },
          {
            key: 'author',
            label: 'Author',
            sortable: true,
            tdClass: 'preLine',
          },
          {
            key: 'created_at',
            label: 'Creation date',
            sortable: true,
          },
          { key: 'actions' },
        ],
        refresh: false,
      };
    },
    computed: {
      submittedFields() {
        const fieldsCopy = [...this.fields];
        fieldsCopy.splice(2, 0, {
          key: 'document_specification.document_id',
          label: 'ID',
          sortable: true,
        });
        return fieldsCopy;
      },
    },
    methods: {
      async createNewProposal() {
        const { data } = await this.axios.post(`documents/new/${this.url.infrastructure}`);
        if (!data.error) {
          await this.$router.push(`/proposals/${this.url.proposalFormUrlPart}${data}`);
          this.$notify({ type: 'success', title: 'Proposal created successfully!' });
        } else {
          this.$notify({ type: 'error', title: 'Proposal creation error' });
        }
      },
      set_refresh(value) {
        this.refresh = value;
      },
    },
  };
</script>
<style lang="scss"
       scoped>
  .card {
    margin: 4rem;
  }
</style>
