<template>
  <div>
    <template v-if="!id_accepted || is_coauthor_pdf_generating">
      <b-row>
        <b-col>
          <h5>Enter your CERIC Proposal ID</h5>
          <b-form-input v-model="document_id"
                        class="mb-2"/>
          <template v-if="!loading">
            <h6 v-if="document_id === '' && id_accepted === false"
                class="red--text"
                v-html="message.empty_id"/>
            <h6 v-else-if="id_accepted === null"
                class="magenta_text"
                v-html="message.before_search"/>
            <h6 v-else
                class="text-warning"
                v-html="message.no_proposal"/>
          </template>
        </b-col>
      </b-row>
      <b-row class="text-right">
        <b-col>
          <button-spinner :loading="loading"
                          class="submit"
                          variant="info"
                          @click="search_ceric_proposal_for_user">
            <template v-slot:[spinner_slot_type]>
              <span v-text="search_btn_text"/>
            </template>
          </button-spinner>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <h6 v-if="is_completed_and_loaded"
          class="green--text"
          v-html="completed_msg"/>

      <ceric_user_proposal_completion_form v-else-if="user_role==='MAIN_AUTHOR'"
                                           :object_id="object_id"
                                           @generate_pdf="addAndGeneratePdf"/>
    </template>
  </div>
</template>

<script>
  import ceric_user_proposal_completion_form from '@/components/proposal/ceric/ceric_user_proposal_completion_form';
  import ButtonSpinner from '@/components/buttonSpinner';

  export default {
    name: 'ceric_user_proposal_completion',
    components: {
      ceric_user_proposal_completion_form,
      ButtonSpinner,
    },
    data() {
      return {
        id_accepted: null,
        user_role: '',
        completed: false,
        loading: false,
        object_id: '',
        document_id: '',
        search_btn_text: 'Search for my CERIC Proposal',
        message: {
          empty_id: 'Proposal ID cannot be an empty field.',
          before_search:
            'If you cannot find the proposal on your list, probably the SOLARIS User Office did not add it to SUN yet.'
            + '<br/>Please contact the SOLARIS User Office for the further assistance.',
          no_proposal:
            'ID not found. Make sure if your proposal number is correct.'
            + '<br/>Maybe your proposal has not been added into SUN by the SOLARIS User Office yet.'
            + '<br/>Wait for an e-mail confirmation of adding your proposal to the SUN.'
            + '<br/>The e-mail will be sent to your e-mail address associated with your CERIC proposal.',
          proposal_found: 'Your proposal has been found. Fill the data below to add it to your proposal.',
          proposal_completed_coauthor: 'Your proposal has been found and now it should be accessible '
            + 'in your submitted proposals list.',
          proposal_completed_mainauthor: 'Your proposal is ready. You should be able to find it on '
            + 'your submitted proposals list.',
        },
      };
    },
    computed: {
      completed_msg() {
        const { proposal_completed_mainauthor, proposal_completed_coauthor } = this.message;
        return this.user_role === 'MAIN_AUTHOR' ? proposal_completed_mainauthor : proposal_completed_coauthor;
      },
      is_coauthor_pdf_generating() {
        return this.user_role === 'CO_AUTHOR' && this.loading;
      },
      is_completed_and_loaded() {
        return this.completed && !this.loading;
      },
      spinner_slot_type() {
        return this.loading ? 'spinnerText' : 'defaultText';
      },
    },
    methods: {
      add_me_to_team_of_ceric_proposal() {
        this.axios.put('/documents/ceric/authors', { id: this.document_id })
          .then((response) => {
            const { error, msg } = response.data;
            if (msg) {
              this.$notify({ type: 'success', title: msg });
              if (this.user_role === 'CO_AUTHOR') {
                this.addAndGeneratePdf();
              }
            } else {
              this.$notify({ type: 'error', title: error });
              this.loading = false;
            }
          })
          .catch(() => {
            this.$notify({ type: 'error', title: 'Error connection' });
            this.loading = false;
          });
      },
      search_ceric_proposal_for_user() {
        this.loading = true;
        const id = this.document_id;
        this.axios.get('/documents/ceric/authors', { params: { id } })
          .then((response) => {
            if (response.data.error) {
              this.id_accepted = false;
              this.loading = false;
            } else {
              this.id_accepted = true;
              const { role, completed, object_id } = response.data;
              this.user_role = role;
              this.completed = completed;
              this.object_id = object_id;
              this.add_me_to_team_of_ceric_proposal();
            }
          })
          .catch(() => {
            this.id_accepted = false;
            this.loading = false;
          });
      },
      addAndGeneratePdf() {
        this.axios.put(`/add_rendered_pdf/${this.object_id}`)
          .then((response) => {
            if (response.data.msg) {
              this.completed = true;
              this.emit_refresh();
            }
            this.loading = false;
          });
      },
      emit_refresh() {
        this.$emit('update_refresh');
      },
    },
    watch: {
      loading: {
        handler() {
          this.$emit('loading', this.loading);
        },
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  .magenta_text {
    color: $color-pink-magenta;
  }
</style>
