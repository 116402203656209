<template>
  <base-icon-button-assignment-returned @click="onClick"/>
</template>

<script>
  import BaseIconButtonAssignmentReturned from '@/components/baseComponents/baseIcons/BaseIconButtonAssignmentReturned';

  export default {
    name: 'ActionDownloadAdditionalSampleDeclarationPdf',
    components: { BaseIconButtonAssignmentReturned },
    props: {
      proposal: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      onClick() {
        return this.$listeners.click || this.downloadDeclaration;
      },
    },
    methods: {
      async downloadDeclaration() {
        const pdfId = this.proposal.additional_sample_declaration.attachments[0];
        await this.downloadPdf(pdfId);
      },
    },
  };
</script>

<style scoped>

</style>
