<template>
  <div>
    <b-modal id="modal_ceric_user_completion"
             :ok-disabled="loading"
             centered
             no-close-on-backdrop
             ok-only
             ok-title="Close"
             ok-variant="secondary"
             size="lg">
      <ceric_user_proposal_completion @loading="loading = $event"
                                      @update_refresh="emit_refresh"/>
    </b-modal>
  </div>
</template>

<script>
  import ceric_user_proposal_completion from '@/components/proposal/ceric/ceric_user_proposal_completion';

  export default {
    data() {
      return {
        name: 'modal_ceric_user_completion',
        loading: false,
      };
    },
    components: { ceric_user_proposal_completion },
    methods: {
      emit_refresh() {
        this.$emit('set_refresh', true);
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  .modal_div {
    min-width: 1270px;
  }

  .error_text {
    margin-top: 18px;
    color: red;
  }
</style>
