<template>
  <b-table :fields="fields"
           :items="documents"
           head-variant="light"
           hover
           responsive
           striped>

    <template v-slot:top-row="{fields}">
      <proposal-filter :id="id"
                       v-model="documents"
                       :fields="fields"
                       :not_include_key="['actions', 'ordinalNumber']">
      </proposal-filter>
    </template>

    <template v-slot:cell(ordinalNumber)="row">
      <base-column-ordinal-number v-bind="{index: row.index, perPage:0, currentPage:1}"/>
    </template>

    <template v-slot:cell(actions)="row">

      <template v-if="isEditable(row.item)">
        <base-icon-button-edit title="Edit proposal"
                               @click="goToProposalForm(row.item)"/>

        <base-icon-button-delete title="Delete proposal"
                                 @click="deleteDocument(row.item._id['$oid'])"/>
      </template>

      <action-download-pdf :disabled="false"
                           @click="handlePdf(row.item)"/>

      <action-download-additional-sample-declaration-pdf v-if="isAdditionalSampleDeclaration(row.item)"
                                                         :proposal="row.item"/>

    </template>
  </b-table>
</template>

<script>
  import ActionDownloadPdf from '@/components/actions/ActionDownloadPdf';
  import ActionDownloadAdditionalSampleDeclarationPdf from
  '@/components/actions/ActionDownloadAdditionalSampleDeclarationPdf';
  import BaseIconButtonDelete from '@/components/baseComponents/baseIcons/BaseIconButtonDelete';
  import BaseIconButtonEdit from '@/components/baseComponents/baseIcons/BaseIconButtonEdit';
  import proposalFilter from '@/components/proposal_filter';
  import { mapGetters } from 'vuex';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import { isProposalOfType } from '@/helpers/proposalForm';

  export default {
    components: {
      ActionDownloadPdf,
      ActionDownloadAdditionalSampleDeclarationPdf,
      BaseColumnOrdinalNumber,
      BaseIconButtonEdit,
      BaseIconButtonDelete,
      proposalFilter,
    },
    props: {
      fields: {
        type: Array,
        required: true,
      },
      mainUrlPart: {
        type: String,
        required: true,
      },
      proposalFormUrlPart: {
        type: String,
        required: true,
      },
      infrastructure: {
        type: String,
        required: true,
      },
      refreshSubmitted: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        documents: [],
        id: 1,
      };
    },
    computed: {
      ...mapGetters('calls', { openCallId: 'current_call_id' }),
      proposalStateFromUrl() {
        return this.mainUrlPart.split('/').find((el) => ['submitted', 'draft'].includes(el));
      },
    },
    methods: {
      goToProposalForm(item) {
        this.$router.push({ path: `/proposals/${this.proposalFormUrlPart}${item._id.$oid}` });
      },
      isAdditionalSampleDeclaration(item) {
        return item.additional_sample_declaration?.states.name === 'submitted';
      },
      isCallOpen(callId) {
        return callId === this.openCallId;
      },
      isProposalCeric(item) {
        const proposalSubtypesObject = item.document_specification.subtype || {};
        return Object.keys(proposalSubtypesObject).includes('ceric');
      },
      isProposalRapidAccess(item) {
        return isProposalOfType(item.general, 'Rapid access');
      },
      isEditable(item) {
        if (this.proposalStateFromUrl === 'submitted') {
          const isProposalOfEditableType = !this.isProposalCeric(item) && !this.isProposalRapidAccess(item);
          return this.isCallOpen(item.call_id) && isProposalOfEditableType;
        }
        return true;
      },
      handlePdf(item) {
        const objectId = item._id.$oid;
        if (item.pdf_id) {
          this.download_document_pdf(objectId);
        } else {
          this.generate_pdf(objectId);
        }
      },
      async deleteDocument(id) {
        try {
          await this.axios.delete(`/documents/${id}`);
          this.removeFromDocuments(id);
        } catch {
          this.notifyDeletingError();
        }
      },
      findDocumentIndex(id) {
        return this.documents.findIndex((doc) => doc._id.$oid === id);
      },
      removeFromDocuments(id) {
        const idx = this.findDocumentIndex(id);
        if (idx > -1) {
          this.$delete(this.documents, idx);
        }
      },
      notifyDeletingError() {
        this.$notify({ type: 'error', title: 'The document couldn\'t be deleted' });
      },
      processDocuments(documents) {
        this.documents = [];
        documents.forEach((document) => {
          // eslint-disable-next-line camelcase
          const created_at = this.created_at(document);
          // eslint-disable-next-line camelcase
          this.documents.push({ ...document, created_at });
        });
        if (this.mainUrlPart.includes('submitted')) {
          this.documents = this.documents.map((document) => {
            const { document_specification = {} } = document;
            // eslint-disable-next-line camelcase
            if ('document_id' in document_specification) {
              return document;
            }
            // eslint-disable-next-line camelcase
            document_specification.document_id = '- (ID will be generated after the call is closed)';
            return {
              ...document,
              // eslint-disable-next-line camelcase
              document_specification,
            };
          });
        }
      },
      async loadData() {
        const { data } = await this.axios.get(this.mainUrlPart);
        this.processDocuments(data.documents);

        // setup the data in proposal-filter, call-filter component
        this.$emit('setup_proposal', this.documents);
      },
    },
    watch: {
      refreshSubmitted() {
        if (this.refreshSubmitted) {
          this.loadData();
          this.$emit('set_refresh', false);
        }
      },
    },
    created() {
      this.loadData();
    },
  };
</script>

<style lang="scss"
       scoped>

</style>
